/**
 * Specsheets
 * 
 * @dependencies
 * * jquery
 * *
 * * jsTranslation.getContext() (erco.js) -> Loading all translations needed for the specsheet after being async loaded
 * * globals.trans.specsheets (erco.js) -> Containing the specsheet translations and available after the jsTranslation.getContext() call
 * * ProductOverview (productoverview.js) -> Contact form initialisation in async loaded Specsheet
 * * CheckboxGroup (erco.js) -> Checkbox initialisation
 * * Globals.renderMustacheTemplates() (erco.js) -> Render untouched mustache render elements probably found in the async loaded form
 * * side_menu_animate.showSpecsheets() (erco.js) -> Show specsheet close button on side menue on open specsheet
 * * side_menu_animate.hideSpecsheets() (erco.js) -> Hide specsheet close button on closed specsheet
 * * side_menu_animate.switchExpandedState (erco.js) -> Used to expand the close button on ocassions when scrolling in the specsheet
 * * toolkit.downloadFile() (toolkit.js) -> Used to download planing data
 * * trackDownload() (?) -> Probably used to track downloads somewhere
 * * Collector.eventListenerAdded (myerco/myerco.js) -> Reseting the flag used to indicate if eventListeners were already added
 * * Collector.injectUI() (myerco/myerco.js) -> Used to inject the collector UI (Marking star)
 * *
 * * pdb-api route: /specsheets/internalArticleInfos/:artno/:lang/?api=v1.1 
 * 
 * @dependents
 * * $(document).ready(function()
 * * css-class spec-contact-form used in pdb-api -> dist/application/v1.1/views/specsheets/specsheet_contact_form.php
 * 
 * @note
 * There is a [data-side-menu="specsheets"] element which is referenced in side_menu_animate() (erco.js)
 * data attribute [data-js-specspinner] is not used in pdp-api / cms-backend / lightscout
 * 
 * 
 * @commit b.kelm (2018-10)
 */

var Specsheets = {
  // Specsheet article number
  'artno': '',
  // Checking if a specsheet is currently active to prevent multiple from loading
  'isActive': false,
  // Keeping track of the location href before showing the specsheet
  'originalLocationHref': window.location.href,
  /**
   * Current certificate download section instance
   * @type {SpecsheetDownloadSection | null}
   */
  'certificateDownloadSection': null,
  /**
   * Current file download section instance
   * @type {SpecsheetDownloadSection | null}
   */
  'fileDownloadSection': null,
  /**
   * Current scroll lock state
   * @type {boolean}
   */
  'scrollLockAlreadySet': false,
  // Init function
  init(artno) {
    authHelper.initJwtAuth();
    // If no specsheet is active
    if (!this.isActive) {
      this.isActive = true;
      this.artno = artno;
      Specsheets.addLoadingSpinner();
      Specsheets.fetch();
      // Fetching translations
      jsTranslation.getContext('specsheets');
    }
  },
  // Fetches the specsheet
  fetch(artno) {
    $.get(confEnv['specsheet_service_url'] + '/specsheet/' + this.artno + '/' + k3vars.S_Sprache, function (data) {
      Specsheets.append(data, artno);
      Specsheets.initSidebarElement();
      Specsheets.initQuery();
      productoverview.initContactForms();
      document.querySelectorAll('.spec-contact-form [data-js-check-checkboxes]').forEach((element) => {
        new CheckboxGroup(element);
      });
      globals.renderMustacheTemplates();

      // Temporary Solution: Set the alternative link for Tesis specsheets
      Specsheets.setAlternativeLinkForTesis();
      // Temporary Solution: Set the alternative link for Pollux specsheets
      Specsheets.setAlternativeLinkForPollux();
    });
  },

  fetchInternalArticleInfos(success, failure) {
    $.ajax({
      url: confEnv['pdb_api_url'] + '/specsheets/internalArticleInfos/' + this.artno + '/' + k3vars.S_Sprache + '/?api=v1.1',
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'html',
      success: (data) => {
        (success || (() => { }))(data);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        (failure || (() => { }))();
      },
    });
  },

  // Toggeling the loading spinner
  toggleLoadingSpinner() {
    var spinnerElms = document.querySelectorAll('[data-js-specspinner]');

    if (spinnerElms.length > 0) {
      for (var i = spinnerElms.length - 1; i >= 0; i--) {
        spinnerElms[i].parentNode.removeChild(spinnerElms[i]);
      }
    } else {
      var triggerElms = document.querySelectorAll('[data-artno="' + this.artno + '"][data-js-loadspecsheet]'),
        spinnerElms = [];

      // Looping all trigger elements and checking which have an image as child
      for (var i = 0; i < triggerElms.length; i++) {
        if (!triggerElms[i].querySelector('img')) {
          var imgElm = document.createElement('img');
          imgElm.src = '/struktur/img/ajax-loader-button.gif';
          imgElm.setAttribute('data-js-specspinner', '');

          triggerElms[i].appendChild(imgElm)
        }
      }
    }
  },
  // Appends the spcsheet data and initializes operations on them
  append(data) {
    var fragment = document.createRange().createContextualFragment(data);

    const wrapElement = document.createElement('div');
    wrapElement.setAttribute('data-scoped-css-v3', '');
    wrapElement.setAttribute('data-js-specsheet', '');
    wrapElement.appendChild(fragment);
    document.getElementsByTagName('body')[0].appendChild(wrapElement);
    Specsheets.scrollLockAlreadySet = document.body.classList.contains('er-scroll--lock');
    Specsheets.toggleBodyScroll();
    side_menu_animate.showSpecsheets();
    Specsheets.initAccExpander();
    Specsheets.initDownloadSection();
    Specsheets.addBtnClose();
    Specsheets.initImages();
    Specsheets.initCheckboxes();
    Specsheets.checkIdrop();
    Specsheets.removeLoadingSpinner();
    Specsheets.fetchCertificates();

    Specsheets.fetchInternalArticleInfos((data) => {
      let specsTableBodyEl = document.querySelector('#specsheet .specs-table tbody');
      var fragment = document.createRange().createContextualFragment(data);
      Array.prototype.slice.call(fragment.querySelectorAll('tr')).reverse().forEach((trEl) => {
        specsTableBodyEl.insertBefore(trEl, specsTableBodyEl.firstChild);
      });
    });
  },
  // Adding the loading spinner
  addLoadingSpinner() {
    var spinnerElms = document.querySelectorAll('[data-js-specspinner]');

    var triggerElms = document.querySelectorAll('[data-artno="' + this.artno + '"][data-js-loadspecsheet]'),
      spinnerElms = [];

    // Looping all trigger elements and checking which have an image as child
    for (var i = 0; i < triggerElms.length; i++) {
      if (!triggerElms[i].querySelector('img')) {
        var imgElm = document.createElement('img');
        imgElm.src = '/struktur/img/ajax-loader-button.gif';
        imgElm.classList.add('spec-spinner');
        imgElm.setAttribute('data-js-specspinner', '');

        triggerElms[i].appendChild(imgElm)
      }
    }
  },
  // Removing the loading spinner
  removeLoadingSpinner() {
    var spinnerElms = document.querySelectorAll('[data-js-specspinner]');

    for (var i = spinnerElms.length - 1; i >= 0; i--) {
      spinnerElms[i].parentNode.removeChild(spinnerElms[i]);
    }
  },
  // Closes the specsheet
  close() {

    document.querySelector('[data-js-specsheet]').parentNode.removeChild(document.querySelector('[data-js-specsheet]'));
    // Removing the ESC event listener
    window.removeEventListener('keydown', specEscClose);
    // Removing the popState event listener
    window.removeEventListener('popstate', specBackClose);
    // Removing the query parameters from the URL
    Specsheets.removeQuery();
    // Reverting the body scroll prevent
    Specsheets.toggleBodyScroll();
    // Hiding the sidebar element if neccesary
    side_menu_animate.hideSpecsheets();
    // Setting the specsheet state to inactive
    this.isActive = false;
  },
  // Removing the specsheet query parameters
  removeQuery() {
    let url = this.originalLocationHref;
    url = window.toolkit.removeQueryParameter('specsheet', url);
    url = window.toolkit.removeQueryParameter('articles', url);

    window.history.pushState(null, '', url);
  },
  // Prevents the body from scrolling in the background and moves the sidebar higher
  toggleBodyScroll() {
    if (!Specsheets.scrollLockAlreadySet) {
      document.body.classList.toggle('er-scroll--lock');
    }
    document.body.classList.toggle('viewing-page-specsheet');
    document.querySelector('[data-side-menu="up"]')?.classList.toggle('d-n');
  },
  // Checking for ESC press to close the specsheet
  addBtnClose() {
    window.addEventListener('keydown', specEscClose = function (e) {
      e = e || window.event;
      var isEscape = false;
      if ('key' in e) {
        isEscape = (e.key == 'Escape' || e.key == 'Esc');
      } else {
        isEscape = (e.keyCode == 27);
      }
      if (isEscape) {
        Specsheets.close();
      }
    });
  },
  // Initializing all neccesary query functions
  initQuery() {
    Specsheets.appendQuery();
    Specsheets.addBackListener();
  },
  // Appending a query to the site URL
  appendQuery() {
    var url = window.location.href,
      specQuery = window.toolkit.getQueryParameter('specsheet', url),
      artQuery = window.toolkit.getQueryParameter('articles', url);

    // Checking if there is already an appropriate query (happens if it is already present on pageload)
    if (!artQuery && !specQuery) {
      // Storing the current url to be able to jump back to it, for when the specsheet is being closed
      this.originalLocationHref = url;

      var newTemporarySpecsheetUrl = window.location.origin + '/' + k3vars.S_Sprache + '/';

      window.history.pushState(
        null,
        this.artno,
        window.toolkit.addQueryParameter('specsheet', this.artno, newTemporarySpecsheetUrl),
      );
    }
  },
  // Adding an event listener to catch the back-button event
  addBackListener() {
    window.addEventListener('popstate', specBackClose = function (e) {
      e.preventDefault();
      Specsheets.close();
    });
  },
  // Checking if a query exists on pageload
  checkQuery() {
    var artno = window.toolkit.getQueryParameter('specsheet', window.location.href),
      artno_alt = window.toolkit.getQueryParameter('articles', window.location.href);

    if (artno || artno_alt) {
      Specsheets.init(artno || artno_alt);
    }
  },
  // Initializes the sidebar closing element
  initSidebarElement() {
    var sidebarElm = document.querySelector('[data-side-menu="specsheets"]');
    if (!sidebarElm.classList.contains('initialized')) {
      sidebarElm.classList.add('initialized');
      sidebarElm.addEventListener('click', function (e) {
        e.preventDefault()
        Specsheets.close();
      });
    }
    // Initializes the slide-out animation
    Specsheets.animateSidebarElement();
    // Adding the scroll event listener
    Specsheets.addScrollWatcher();
  },
  // Checking on scroll to hide the sidebar element animation
  addScrollWatcher() {
    var elm = document.querySelector('.specsheet');

    // Removing the expanded sidebar state on scroll
    elm.addEventListener('scroll', specScroll = function (e) {
      var top = elm.scrollTop,
        bottom = elm.scrollHeight - elm.scrollTop - elm.clientHeight;

      var sidebarElm = $('[data-side-menu="specsheets"]'),
        offset = 50;

      if (top < offset || bottom < offset) { // Element should be showing
        if (!sidebarElm.hasClass('expand_active')) {
          side_menu_animate.switchExpandedState(false, sidebarElm);
        }
      } else { // Element should be hidden
        if (sidebarElm.hasClass('expand_active')) {
          side_menu_animate.switchExpandedState(false, sidebarElm);
        }
      }
    });
  },
  // Expands/Collapses the sidebar element
  animateSidebarElement() {
    setTimeout(function () {
      var sidebarElm = $('[data-side-menu="specsheets"]');
      side_menu_animate.switchExpandedState(false, sidebarElm);
    }, 400);
  },
  // Initializes the specsheet acceccoir expander if applicable
  initAccExpander() {
    var expander = document.querySelector('.acc-extend');

    if (expander) {
      expander.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector('.spec-acc-clear').classList.toggle('extended');
        var accExpander = document.querySelector('.acc-extend');
        accExpander.classList.toggle('extended');

        if (globals.trans.specsheets) {
          var textElm = accExpander.querySelector('.btn-text');
          var textContent = accExpander.classList.contains('extended') ?
            globals.trans.specsheets['weniger_zubehör_anzeigen'] :
            globals.trans.specsheets['weiters_zubehör_anzeigen'];
          window.toolkit.clear(textElm);
          textElm.appendChild(document.createTextNode(textContent));
        }
      });
    }
  },
  // Checking the size of images if neccesary and adjusting them
  initImages() {
    var nodes = document.getElementsByClassName('specsheet')[0].getElementsByClassName('img-container');

    for (var i = 0; i < nodes.length; i++) {
      var node = nodes[i];

      if (node.getElementsByTagName('img')[0].getAttribute('alt') === 'Produktfoto - Zeichnung') {
        Specsheets.sizeImage(node);
      } else {
        Specsheets.showImgOnLoad(node);
      }
    }
  },
  // Sizing the image
  sizeImage(node) {
    var img = node.getElementsByTagName('img')[0],
      container = node.parentNode;

    img.addEventListener('load', function () {
      // Getting image width, height and aspect ratio
      var width = img.width,
        height = img.height,
        r = img.width / img.height;

      // Removing the default fallback class
      container.classList.remove('col-sm-8');

      // Sizing the image
      if (r < 0.8) { // Vertical
        if (r > 0.4) { // "Normal" Vertical
          container.classList.add('col-sm-4');
        } else { // "Extreme" Vertical
          container.classList.add('col-sm-2');
        }
      } else if (r >= 0.8 && r < 1.3) { // Square
        container.classList.add('col-sm-4');
      } else { // Horizontal
        if (r < 2.5) { // "Normal" Horizontal
          container.classList.add('col-sm-8');
        } else { // "Extreme" Horizontal
          container.classList.add('col-sm-12');
        }
      }

      // Displaying the image
      node.classList.add('loaded');
    });
  },
  showImgOnLoad(node) {
    var img = node.getElementsByTagName('img')[0];

    img.addEventListener('load', function () {
      node.classList.add('loaded');
    });
  },
  // Downloading a single element from a specsheet
  downloadSingleFile({ id, type }) {
    if(id == 15){
      Specsheets.generateSpecsheet()
    } else {
      Specsheets.downloadSpecItems([id]);
    }
    Specsheets.sendAnalyticsData([type]);
  },
  downloadMultipleFiles(downloads) {
    const { ids, types } = downloads.reduce((acc, download) => {
      acc.ids.push(download.id);
      acc.types.push(download.type);
      return acc;
    }, {ids: [], types: []});
    
    if (ids) {
      if(ids.length === 1 && ids[0] == 15){
        Specsheets.generateSpecsheet();
      } else {
        Specsheets.downloadSpecItems(ids);
      }
      Specsheets.sendAnalyticsData(types);
    }
  },
  async downloadSpecItems(ids) {
    // Set the downloadspinner
    this.fileDownloadSection.disableDownloadButton();
    this.fileDownloadSection.showSpinner();

    // Create URL
    const url = confEnv['download_service_url'] + '/download/' + this.artno + '/' + k3vars.S_Sprache + '/?types=' + ids.join() + '&formattedId&productType=article';

    // try the download
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const blob = await response.blob();
      const bloblUrl = window.URL.createObjectURL(blob);
      const filename = response.headers
        .get('Content-Disposition')
        .match(/filename="(?<zipName>.*?)"/).groups.zipName;

      window.toolkit.downloadFile(bloblUrl, true, filename);
    } catch (error) {
      // Just log the error if we have no instance of our download section
      if (!this.fileDownloadSection) return console.error(error);


      let message = globals.trans.specsheets['es_ist_ein_fehler_aufgetreten'] || 'An error occured while downloading the file';

      // Show a new message box
      this.fileDownloadSection.newMessageBox(message, 'danger');
    }
    // Remove the downloadspinner
    this.fileDownloadSection.enableDownloadButton();
    this.fileDownloadSection.hideSpinner();
  },
  // Sending analytic data to Google
  sendAnalyticsData(data) {
    if (typeof trackDownload == "function") {
      for (var i = 0; i < data.length; i++) {
        var analyticString = 'download/' + this.artno + '/' + k3vars.S_Sprache + '/' + new Date().getFullYear() + '/' + data[i];
        trackDownload(analyticString);
      }
    }
  },
  initDownloadSection() {
    const section = document.querySelector('#specsheet .specsheet-downloads');
    this.fileDownloadSection = new SpecsheetDownloadSection(section, Specsheets.downloadMultipleFiles, Specsheets.downloadSingleFile);
  },
  initCheckboxes() {
    // This forces the Collector to setup the new listeners.
    // Otherwise the listeners dont work on the specsheet popup ^schi
    Collector.eventListenerAdded = false;
    Collector.injectUI($('#specsheet'));
  },
  checkIdrop() {
    if (!navigator.appVersion.toLowerCase().includes('win')) {
      document.querySelectorAll('[data-specsheet-idrop]').forEach(el => el.style.display = 'none');
    }
  },
  generateSpecsheet(){
    document.querySelector('#pdfSpecGenerationBtn').click();
  },
  /**
   * Download a single Certificate 
   * @param {SelectedDownload} download - Selected download
   */
  downloadSingleCertificate(download) {
    Specsheets.downloadMultipleCertificates([download]);
  },
  /**
   * Download multiple certificates
   * @param {SelectedDownload[]} downloads - Selected downloads
   */
  async downloadMultipleCertificates(downloads) {
    const certs = downloads.map(({id}) => id).join(',');
    const url = `${confEnv.download_service_url}/certificates/${this.artno}/${k3vars.S_Sprache}?certs=${certs}&formattedId`

    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      const blob = await response.blob();
      const bloblUrl = window.URL.createObjectURL(blob);
      const filename = response.headers
        .get('Content-Disposition')
        .match(/filename="(?<certName>.*?)"/).groups.certName;

      window.toolkit.downloadFile(bloblUrl, true, filename);
    } catch (error) {
      // Just log the error if we have no instance of our download section
      if (!this.certificateDownloadSection) return console.error(error);

      // Get translation for certs
      let translations = globals?.trans?.certificates;

      if (!translations) {
        await new Promise((resolve) => {
          jsTranslation.getContext('certificates', resolve);
        }).then(() => {
          translations = globals.trans.certificates;
        });
      }

      // Set proper message depending on error
      let message = 'An error occured while downloading the certificate';
      if (error.message === '404') {
        message =
          translations.zertifikate_download_fehler ||
          'Document not found, please contact I/RD/TC';
      }

      // Show a new message box
      this.certificateDownloadSection.newMessageBox(message, 'danger');
    }
  },
  fetchCertificates() {
     authHelper.isStaff.then((isStaff) => {
      if (!isStaff) throw new Error('User is not staff');

      const url = `${confEnv['specsheet_service_url']}/certificates/${this.artno}/${k3vars.S_Sprache}`;
      
      return fetch(url, {
        credentials: 'include',
      })
     }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.text();
     }).then(html => {

      const downloadSection = document.querySelector('#specsheet .specsheet-downloads');
      const fragment = document.createRange().createContextualFragment(html);
      this.certificateDownloadSection = new SpecsheetDownloadSection(fragment, Specsheets.downloadMultipleCertificates.bind(this), Specsheets.downloadSingleCertificate);

      downloadSection.after(fragment);
     }).catch((err) => {
      // Do nothing if we get our own is not staff error, cause we dont want to
      // show the cert section, so that error is expected
      if (err.message === 'User is not staff') return;
      console.error(err);
    })
  },
  // Temporary Solution: Set alternative link for Tesis HMTL and PDF specs
  async setAlternativeLinkForTesis() {
    // check for html spec phaseout elements
    const htmlPhaseoutDiv = document.querySelector('#specsheet .spec-phaseout');
    if (!htmlPhaseoutDiv) return;

    const htmlAlternativeSpan = htmlPhaseoutDiv.querySelectorAll('span')[1];
    if (!htmlAlternativeSpan) return;

    const htmlAlternativeAnchor = htmlAlternativeSpan.querySelector('a');
    if (!htmlAlternativeAnchor) return;

    // check for pdf spec phaseout elements
    const pdfPhaseoutAlternativeSpan = document.querySelector('[data-pdf-spec-phaseout="alternative"]');
    if (!pdfPhaseoutAlternativeSpan) return;

    const pdfAlternativeAnchor = pdfPhaseoutAlternativeSpan.querySelector('a');
    if (!pdfAlternativeAnchor) return;

    // fetch data
    const currentDate = new Date();
    const url = `/cdn/specsheets/mapping-tesis.json?t=${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}${currentDate.getHours()}`;
    const res = await fetch(url);
    if (!res.ok) return;
    const data = await res.json();

    // map data
    const lang = k3vars.S_Sprache === 'en_us' ? 'us' : 'eu';
    const mapping = data[lang];
    if (!mapping) return;

    // find mapping entry
    const mappingEntry = mapping.find(entry => entry.oldId === this.artno);
    if (!mappingEntry) return;

    const alternativeArticleNumber = mappingEntry.newId;
    
    // set html spec phaseout
    htmlAlternativeAnchor.href = `https://www.erco.com/${k3vars.S_Sprache}?specsheet=${alternativeArticleNumber}`;
    htmlAlternativeAnchor.innerText = htmlAlternativeAnchor.innerText + ` ${alternativeArticleNumber}`;

    // set pdf spec phaseout
    pdfAlternativeAnchor.innerText = pdfAlternativeAnchor.innerText + ` ${alternativeArticleNumber}`;

    const pdfPhaseoutAlternativeLink = document.createElement('a');
    pdfPhaseoutAlternativeLink.href = `https://www.erco.com/${k3vars.S_Sprache}?specsheet=${alternativeArticleNumber}`;
    pdfPhaseoutAlternativeLink.innerHTML = `www.erco.com/${k3vars.S_Sprache}?specsheet=${lang === "us" ? "<br>" : ""}${alternativeArticleNumber}`;
    pdfPhaseoutAlternativeSpan.appendChild(document.createElement('br'));
    pdfPhaseoutAlternativeSpan.appendChild(pdfPhaseoutAlternativeLink);
  },
  // Temporary Solution: Set alternative link for Pollux HMTL and PDF specs
  async setAlternativeLinkForPollux() {
    // check for html spec phaseout elements
    const htmlPhaseoutDiv = document.querySelector('#specsheet .spec-phaseout');
    if (!htmlPhaseoutDiv) return;

    const htmlAlternativeSpan = htmlPhaseoutDiv.querySelectorAll('span')[1];
    if (!htmlAlternativeSpan) return;

    const htmlAlternativeAnchor = htmlAlternativeSpan.querySelector('a');
    if (!htmlAlternativeAnchor) return;

    // check for pdf spec phaseout elements
    const pdfPhaseoutAlternativeSpan = document.querySelector('[data-pdf-spec-phaseout="alternative"]');
    if (!pdfPhaseoutAlternativeSpan) return;

    const pdfAlternativeAnchor = pdfPhaseoutAlternativeSpan.querySelector('a');
    if (!pdfAlternativeAnchor) return; 

    // fetch data
    const currentDate = new Date();
    const url = `/cdn/specsheets/mapping-pollux.json?t=${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}${currentDate.getHours()}`;
    const res = await fetch(url);
    if (!res.ok) return;
    const data = await res.json();

    // map data
    const lang = k3vars.S_Sprache === 'en_us' ? 'us' : 'eu';
    const mapping = data[lang];
    if (!mapping) return;

    // find mapping entry
    const mappingEntry = mapping.find(entry => entry.oldId === this.artno);
    if (!mappingEntry) return;
    
    const alternativeArticleNumber = mappingEntry.newId;
    const alternativeArticleFamily = mappingEntry.newFamily;
    
    // set html spec phaseout
    htmlAlternativeAnchor.href = `https://www.erco.com/${k3vars.S_Sprache}?specsheet=${alternativeArticleNumber}`;
    htmlAlternativeAnchor.innerText = `${alternativeArticleFamily} ${alternativeArticleNumber}`;

    // set pdf spec phaseout
    pdfAlternativeAnchor.innerText = `${alternativeArticleFamily} ${alternativeArticleNumber}`;

    const pdfPhaseoutAlternativeLink = document.createElement('a');
    pdfPhaseoutAlternativeLink.href = `https://www.erco.com/${k3vars.S_Sprache}?specsheet=${alternativeArticleNumber}`;
    pdfPhaseoutAlternativeLink.innerHTML = `www.erco.com/${k3vars.S_Sprache}?specsheet=${lang === "us" ? "<br>" : ""}${alternativeArticleNumber}`;
    pdfPhaseoutAlternativeSpan.appendChild(document.createElement('br'));
    pdfPhaseoutAlternativeSpan.appendChild(pdfPhaseoutAlternativeLink);
  },
};
