class ShowMore {
  constructor(element) {
    this.ellipsis = '...';
    this.element = element;

    // Generate unique id for the element to be used in aria-controls
    this.element.id = `show-more-${Math.random().toString(36).substring(2, 9)}`;

    const text = this.element.textContent;
    const maxWordsCount = 50;
    const wordsCount = this.countWords(text);

    if (wordsCount <= maxWordsCount) {
      this.element.classList.remove('show-more')
      return;
    }

    this.configAttribute = this.element.dataset.jsShowMore;

    if (this.configAttribute) {
      try {
        this.configJSON = JSON.parse(this.configAttribute) || {};
      } finally {
        this.config = {
          buttonLabelShowMore: this.configJSON?.buttonLabelShowMore || 'Show more',
          buttonLabelShowLess: this.configJSON?.buttonLabelShowLess || 'Show less',
        }
      }
    }
    const wordsArray = text.split(' ');
    this.isExpanded = false;
    this.visibleWords = wordsArray.slice(0, maxWordsCount).join(' ');

    // remove . from the last word
    if (this.visibleWords.slice(-1) === '.') {
      this.visibleWords = this.visibleWords.slice(0, -1);
    }

    this.elementCollapsed = document.createElement('p');
    this.elementCollapsed.innerHTML = `${this.visibleWords}${this.ellipsis}`;
    this.elementExpanded = document.createElement('div');
    this.elementExpanded.innerHTML = this.element.innerHTML;

    this.buttonElement = document.createElement('button');
    this.buttonElement.classList.add('button', 'button--secondary', 'button--small', 'button--with-icon', 'm-t-5');
    this.buttonElement.setAttribute('aria-hidden', 'true');
    this.buttonElement.innerHTML = `<span class="button__icon icon icon--expand"></span>`;
    this.buttonElementLabel = document.createElement('span');
    this.buttonElementLabel.innerHTML = this.config.buttonLabelShowMore;
    this.buttonElement.appendChild(this.buttonElementLabel);
    this.buttonElement.addEventListener('click', this.toggleReadMore);
    this.buttonElement.setAttribute('aria-expanded', 'false');
    this.buttonElement.setAttribute('aria-controls', this.element.id);


    this.element.classList.add('read-more__content');
    this.element.innerHTML = '';
    this.element.appendChild(this.elementCollapsed);
    this.element.appendChild(this.buttonElement);
    this.element.setAttribute('data-show-more-initilized', 'true');
  }

  toggleReadMore = () => {
    this.buttonElement.classList.toggle('show-more__button--open');
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.elementCollapsed.replaceWith(this.elementExpanded);
      this.buttonElementLabel.innerHTML = this.config.buttonLabelShowLess;
      this.buttonElement.setAttribute('aria-expanded', 'true');
    } else {
      this.elementExpanded.replaceWith(this.elementCollapsed);
      this.buttonElementLabel.innerHTML = this.config.buttonLabelShowMore;
      this.buttonElement.setAttribute('aria-expanded', 'false');
    }
  }

  countWords = (str) => {
    return str.trim().split(/\s+/).length;
  }

  static initSections() {
    const showMoreElements = document.querySelectorAll('[data-js-show-more]');
    document.querySelectorAll('[data-js-show-more]').forEach((element) => {
      new ShowMore(element);
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ShowMore.initSections();
});
