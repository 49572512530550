/**
 * Sidemenu
 * Includes code from Expanding Sidebar Elements & Side Menu Animate
 *
 * @dependencies
 * * jquery
 * * holy
 * * k3vars
 *
 * @dependents
 * * $(document).ready(function() (erco.js)
 * * microsite (modules/microsite.js)
 * * transition.ausklappen (erco.js)
 * * onepager (erco.js)
 * * backLinkReferrer (erco.js)
 * * Specsheets (modules/specsheets.js)
 * * comparLoop (compar.js)
 * * overwriteTransition (lichtbericht.js)
 * * overlayTabNavigation (lichtwissen.js)
 *
 *
 *
 * @note
 * [data-side-menu="specsheets"] is not used in cms-backend or pdb-api
 * [data-side-menu="collections"] is not used in cms-backend or pdb-api
 * [data-side-menu="bugtracker"] is not used in cms-backend or pdb-api
 *
 * @commit (Side Menu Animate)
 * * Initial commit 2.0
 * * s.schaefermeier(2018-03-16) Update von gw2.erco.org
 * * j.pereira(2023-01-05) fix(erco.js): rework visibility of bugtracker button
 *
 * @commit (Expanding Sidebar Elements)
 * * s.schaefermeier(2018-03-16) Update von gw2.erco.org
 * * s.schiffer(2019-01-14) implemented the bugtracker menu icon in the sidebar.
 */

/* Side Menu Objekt
============================================================*/
var side_menu_animate = new (function () {
  var self = this;
  var sideMenu = document.querySelector('[data-js-side-menu]');
  var buttonSpecsheets = $('[data-side-menu="specsheets"]');
  var buttonClose = $('[data-side-menu="close"]');
  var buttonUp = $('[data-side-menu="up"]');
  var buttonCollection = $('[data-side-menu="collections"]');
  var buttonSocial = $('[data-side-menu="social"]');
  var buttonContact = $('[data-side-menu="contact"]');
  var buttonBugtracker = $('[data-side-menu="bugtracker"]');
  var buttonMute = $('[data-side-menu="mute"]');
  var baseWidth = $('.side_menu_section').width();
  var opt = {
    inview: { margin: '0' },
    outview: { margin: '0 -' + 7 * baseWidth + 'px -' + baseWidth + 'px ' + 6 * baseWidth + 'px' },
    params: { duration: 250 },
  };
  var closeTarget = $();

  function show(ele) {
    ele.addClass('-active');
  }

  function hide(ele) {
    if (ele.hasClass('expand_active')) {
      side_menu_animate.switchExpandedState(false, ele);
      setTimeout(function () {
        ele.removeClass('-active');
      }, 750);
    } else {
      ele.removeClass('-active');
    }
  }

  function forceHide(ele) {
    ele.addClass('-force-hide');
  }

  function forceHideRemove(ele) {
    ele.removeClass('-force-hide');
  }

  // Mute
  self.hideMute = function () {
    hide(buttonMute);
  };

  self.showMute = function () {
    show(buttonMute);
  };

  self.assignMute = function (func) {
    buttonMute.off('click');
    if (func === undefined) return;

    buttonMute.on('click', func);
  };

  self.forceHideMute = function () {
    if (!buttonMute.hasClass('-active')) forceHide(buttonMute);
  };

  self.forceHideMuteRemove = function () {
    forceHideRemove(buttonMute);
  };

  self.checkMute = function (func) {
    func.call(buttonMute.get(0));
  };

  // up
  self.hideUp = function () {
    hide(buttonUp);
  };

  self.showUp = function () {
    show(buttonUp);
  };

  self.assignButton = function (func) {
    buttonUp.off('click').on('click', func);
  };

  self.forceHideUp = function () {
    forceHide(buttonUp);
  };

  self.forceHideUpRemove = function () {
    forceHideRemove(buttonUp);
  };

  // close
  self.showClose = function () {
    show(buttonClose);
  };

  self.hideClose = function () {
    hide(buttonClose);
  };

  self.assignClose = function (ele) {
    buttonClose.off('click');
    if (ele === undefined) return;

    buttonClose.on('click', function () {
      hide(buttonClose);
      ele.find('.expander_close, [data-last-page-link]').triggerHandler('click');
    });
  };

  self.triggerClose = function () {
    buttonClose.triggerHandler('click');
  };

  // ss 20160618 zeige den Sharebutton
  self.showSocial = function () {
    show(buttonSocial);
  };

  // 20171005 zeige collection button
  self.showCollection = function () {
    show(buttonCollection);
  };

  // showing the contact button
  self.showContact = function () {
    show(buttonContact);
  };

  // Showing the specsheet button BK 6.11.2018
  self.showSpecsheets = function () {
    show(buttonSpecsheets);
  };

  self.hideSpecsheets = function () {
    hide(buttonSpecsheets);
  };

  self.showBugtracker = function () {
    const showBugTrackerButton = () => {
      show(buttonBugtracker);

      var bugtrackerAnchor = buttonBugtracker.find('> a');

      if (bugtrackerAnchor) {
        bugtrackerAnchor.on('click', function (e) {
          const lang = ['en', 'de'].indexOf(k3vars.S_Sprache) < 0 ? 'en' : k3vars.S_Sprache;
          const url =
            bugtrackerAnchor.attr('href').split('?')[0] +
            '?lang=' +
            lang +
            '&url=' +
            encodeURIComponent(window.location.href);

          bugtrackerAnchor.attr('href', url);
        });
      }
    };

    const hideBugTrackerButton = () => {
      buttonBugtracker.remove();
    };

    const clientIsConnectedToVPNPromise = new Promise((resolve) => {
      // Check if user is connected to VPN if it is not a staff member or not logged in
      $.getJSON(spezial_js_url.clientIsConnectedToVPN, { format: 'json' })
        .done(function (response) {
          resolve(response.connected);
        })
        .fail(function (jqxhr, textStatus, error) {
          var err = textStatus + ', ' + error;
          console.error('Request Failed: ' + err);
          resolve(false);
        });
    });

    clientIsConnectedToVPNPromise.then((connectedToVPN) => {
      if (connectedToVPN) {
        showBugTrackerButton();
      } else {
        authHelper.isStaff.then((isStaff) => {
          if (isStaff) {
            showBugTrackerButton();
          } else {
            hideBugTrackerButton();
          }
        });
      }
    });
  };

  $(window).on('holy:toTop', function () {
    hide(buttonUp);
  });
  $(window).on('holy:fromTop', function () {
    show(buttonUp);
  });
  $(window).on('keyup', function (e) {
    if (e.keyCode == 27) self.triggerClose();
  });

  // Scroll up click
  buttonUp.on('click', function () {
    holy.scrollTo();
  });

  // Social expand
  buttonSocial.on('click', function (e) {
    side_menu_animate.switchExpandedState(e, $(this));
  });

  // Expanding the contact element
  buttonContact.on('click', function (e) {
    side_menu_animate.switchExpandedState(e, $(this));
  });

  // Expanding the collection element
  buttonCollection.on('click', function (e) {
    side_menu_animate.switchExpandedState(e, $(this));
  });

  // Expanding the larger sidebar elements
  self.switchExpandedState = function (e, elm) {
    var hasAction = false;

    // Checking if the clicked element is the sidebar button
    if (
      (e && e.target.classList.contains('side_menu_section')) ||
      (e && e.target.classList.contains('side_menu_ico'))
    ) {
      hasAction = true;
    }

    if (!e || hasAction === true) {
      var opener = elm.find('span').first();

      if (elm.hasClass('expand_active')) {
        // Element is being closed

        var closer = elm[0];

        closer.classList.add('delayBackground');

        setTimeout(function () {
          closer.classList.remove('delayBackground');
        }, 300);

        collapseAllExpanded();
        // Resetting the inner content to its initial value
        setTimeout(function () {
          opener[0].addEventListener('click', ExpandingSidebar.switchSegment(opener[0]));
        }, 300);
      } else {
        // Element is being expanded

        // Checking if other elements need to be closed and delaying the animation
        var animationDelay = 0;
        if (document.getElementsByClassName('expand_active').length > 0) animationDelay = 200;
        // Collapsing all Elements
        collapseAllExpanded();
        // Opening the clicked element
        setTimeout(function () {
          opener.removeClass(opener.data('originalicon')).addClass('newicon-65-0');
          // Collapsing all sidebar elements on mobile
          var side_menu_sections = document.getElementsByClassName('side_menu_section');

          elm.addClass('expand_active');
          $('.sidebar_menu_expand[data-side-expand="' + elm.data('side-menu') + '"]').addClass('active');
          // Resetting the inner content to its initial value
          setTimeout(function () {
            opener[0].addEventListener('click', ExpandingSidebar.switchSegment(opener[0]));
          }, 300);
        }, animationDelay);
      }
    }
  };

  // Collapsing all sidebar element
  collapseAllExpanded = function () {
    var expandedMenus = document.getElementsByClassName('expand_active');

    for (var i = expandedMenus.length - 1; i >= 0; i--) {
      var elm = expandedMenus[i];

      // Resetting the icon
      var opener = elm.getElementsByClassName('side_menu_ico')[0];
      if (opener.classList.contains('newicon-65-0')) {
        opener.classList.remove('newicon-65-0');
        opener.classList.add(opener.getAttribute('data-originalicon'));
      }

      // Resetting the inner and outer container
      var innerExpand = elm.getElementsByClassName('sidebar_menu_expand')[0].classList.remove('active');
      elm.classList.remove('expand_active');
    }

    // Showing all sidebar elements on mobile
    var side_menu_sections = document.getElementsByClassName('side_menu_section');
    for (var i = 0; i < side_menu_sections.length; i++) {
      side_menu_sections[i].classList.remove('collapsed');
    }
  };

  // Event listener for the closing buttons on mobile
  $('.close_expand_mobile').on('click', function (e) {
    e.preventDefault();

    collapseAllExpanded();
  });
})();

ExpandingSidebar = {
  // Adding the event listener to the sidebar buttons
  init: function () {
    // Loading the local contact data
    // Auskommentiert. Wird nun via Mustache Teplates gelöst //vs 20180228
    //ExpandingSidebar.getLocalData();

    // Showing the contact button
    side_menu_animate.showContact();

    // Showing the collection button
    side_menu_animate.showCollection();

    // Showing (or not) the bugtracker link
    side_menu_animate.showBugtracker();

    // Event listener for switching the inner sidebar segments
    var sidebarButtons = document.getElementsByClassName('side-btn-switch');

    for (var i = 0; i < sidebarButtons.length; i++) {
      sidebarButtons[i].addEventListener('click', function (e) {
        e.preventDefault();
        ExpandingSidebar.switchSegment(e.target);
      });
    }
  },

  // Switching the expanded sidebar content
  switchSegment: function (elm) {
    var switchTarget = elm.getAttribute('data-sideelm');

    var container = document.getElementsByClassName('sidebar-children');

    for (var i = 0; i < container.length; i++) {
      var containerid = container[i].getAttribute('data-js-id');
      if (containerid === 'sidebar-' + switchTarget + '-container') {
        container[i].style.display = 'block';
        // Selecting the first input in the segment
        var inputs = container[i].getElementsByTagName('input');
        if (inputs && inputs[0]) inputs[0].select();
      } else {
        container[i].style.display = 'none';
      }
    }
  },
};
