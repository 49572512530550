function build_spotlight(){
  // suchfenster einbauen
  var $div_gui_suchergebnis = $("<div data-target='gui_suchergebnis'></div>");// new Element('div',{'id':'gui_suchergebnis'});
  var $div_artno_fam_suche  = $("<div data-target='artno_fam_suche'></div>");// new Element('div',{'id':'artno_fam_suche', 'class':'popupBody'});
  var $div_spotlight_suche  = $("<div data-target='spotlight_suche'></div>");// new Element('div',{'id':'spotlight_suche'});

  $div_gui_suchergebnis.append($div_artno_fam_suche);
  $div_gui_suchergebnis.append($div_spotlight_suche);

  $('[data-target="such_ergebnisse"]').empty().append($div_gui_suchergebnis);
}


var suche_url = "";
var suche_input_array = new Array();
var suche_select_array = new Array();
var suche_browser = ""; //getBrowser();
var suche_loader_small = "<div id='artno_search_header'><img src=\"/struktur/images/erco_loading_small.gif\" alt=\"\" /></div>";
var suche_eintrefferurl = ""; // Variable wird bei Eintrefferergebnissen gefuellt von Artikelnummersuche
var suche_checkproduktnr = "";
var suche_last_entry = false;
var suche_stichwort = "";
var check_suchwort_aktiv = false;
var suchterm_untouched = false;
var artikelnummertreffer = false;
var gui_suchergebnis_clicked = false;
var pkm_range = false;
var jetzt = new Date();
var time_last_stichwortsuche = jetzt.getTime();
var ajax_loader_datei = "ajax-loader-button.gif";//"ajax_loader";
var navsprache = (k3vars.S_Sprache !='' ? k3vars.S_Sprache : 'en');

suche_translate = new Object();
suche_translate["da_suchen"] = "s&oslash;g";
suche_translate["de_suchen"] = "suchen";
suche_translate["en_suchen"] = "Search";
suche_translate["en_gb_suchen"] = "Search";
suche_translate["en_jp_suchen"] = "Search";
suche_translate["en_us_suchen"] = "Search";
suche_translate["fr_suchen"] = "chercher";
suche_translate["it_suchen"] =  "cerca";
suche_translate["es_suchen"] =  "buscar";
suche_translate["sv_suchen"] =  "s&ouml;k";
suche_translate["nl_suchen"] =  "zoeken";
suche_translate["no_suchen"] =  "S&oslash;k";



function finden(aktion, wert, wert2){


  function check_artno(wert){
    event.preventDefault();
    suchen(wert.children[0]);
  }

  /* ID-------------------
  leert das suchefeld bei Bedarf */

  function clear( obj ){
    return true;
  }

  /* ID-------------------
  weist dem Suchfeld bei Bedarf den letzten Wert zu */

  function blur(obj){
  }

  /* ID-------------------
  öffnet das Ergebnisfenster */

  function show_artikelnummersuche(){
    return true;
  }

  /* ID-------------------
  schließt das Ergebnisfenster */

  function hide_artikelnummersuche(force){
    $( '[data-target="gui_suchergebnis"]' ).fadeOut( "slow" );
  }

  /*
  * Ermöglicht die Produktarchiv-suche
  * Hinweis: Nur eine korrekte Artikelnummer (xxxxx.xxx) wird weiterverarbeitet
  * KT - 15.10.2018
  */
  function produktarchiv(suchwert) {

    var url = '';
    var ergebnis_html;
    var target = '[data-target="spotlight_suche"]';
    var suchwert = $('[data-nav-search]:visible').val();
    suchwert = suchwert.replace(/^\s+|\s+$/g,'');
    suchwert = suchwert.replace(/[&\/\\#,+()$@~%'":!*?<>{}]/gi, "");
    re = new RegExp('^[A-Za-z][0-9]{9}|[0-9]{5}\.?[0-9]{3}');

    if(suchwert.match(re)){

      // "alter" Text und Buttons sollen nicht mehr angezeigt werden
      $('#search_text').addClass('hidden');
      $('#productarchiv').addClass('hidden');
      $('#not-found').addClass('hidden');

      // einblenden des Informationsstextes der Suche
      $('#productarchiv_load').removeClass('hidden');
      $(target).append("<div class=\"p-t-3\"> <img src=\"/struktur/img/"+ajax_loader_datei+"\" id=\"ajax_loader_artnr\" alt=\"please wait while loading\" border=\"1px\"/> </div>");

      url = confEnv['pdb_api_url']+"/index.php/productarchive/productarchive/"+navsprache+"/archive/"+encodeURI(suchwert) + "?api=v1.1";

      $.ajax({
        url :url,
        method:'get',
        dataType: "html",
        success: function(transport){

          $(target).empty();
          var antwort = transport;
          $(target).html(antwort);

        }
      });
    }
  }


  /*
  * Gibt Infotext für Produktarchiv wieder
  * KT - 19.11.2018
  */
  function produktarchivinfo(suchwert){
    var target = '[data-target="spotlight_suche"]';


    // nur bei einer kompletten Artikelnummer wird die Produktarchivsuche angeboten
    // hier müssen leider die Buttons und der Text nachgeladen werden, da sonst der Teil ins Perl Skript gelandet wäre
    url =  confEnv['pdb_api_url']+"/index.php/productarchive/get_loader/"+navsprache+"/archive/"+encodeURI(suchwert);

    $.ajax({
      url :url,
      method:'get',
      dataType: "html",
      success: function(transport){
        var antwort = transport;
        $(target).html(antwort);
        showDesignDataArchiv();
      }
    });
  }

  /**
   * Check if the user is signed in into MyErco and is member of staff and show the internal design data archiv.
   * If the user isn't logged in and not staff, the button will be removed.
   * KT - 18.02.2020
   */
  async function showDesignDataArchiv() {
    try {
      const isStaff = await authHelper.isStaff;

      let buttonDesignDataArchiv = document.getElementById('design-data-archiv');

      if (isStaff) {
        buttonDesignDataArchiv.classList.remove('d-n');
      } else {
        buttonDesignDataArchiv.remove();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function suchen(obj){

    const spotlightResults = $('[data-target="spotlight_suche"]');
    const artnoResults = $('[data-target="artno_fam_suche"]');

    suche_last_entry = obj.value;

    // Only start search logic if input has a value and value is different from the last search
    if(($('[data-nav-search]:visible').val()) && ($('[data-nav-search]:visible').val() != suche_checkproduktnr)){

      suche_checkproduktnr = $('[data-nav-search]:visible').val();

      // Suchwert bereinigen
      var suchwert = $('[data-nav-search]:visible').val();
      // Remove leading "10" and "12", because official article numbers do not start with it

      if(suchwert.length > 9) {
        suchwert = suchwert.replace(/^\s*10|^\s*12/, "");
      }

      $('[data-nav-search]:visible').val(suchwert);// = suchwert;

      // Remove leading and trailing blanks
      suchwert = suchwert.replace(/^\s+|\s+$/g,'');
      // Remove special character
      suchwert = suchwert.replace(/[&\/\\#,+()$@~%'":!*?<>{}]/gi, "");

      if(suchwert.length > 1){

        var ergebnis_html;

        // Suchergebnismeldung bei Anfrage weglassen - KT - 06.06.2018
        spotlightResults.empty();

        /* ID-------------------
        Artno & Produktsuche */

        var url = '';

        // This regular expression is used for article number search. It matches strings based on three criteria:
        // 1. An article number starting with a letter (either uppercase or lowercase), followed by 1 to 9 digits
        // 2. An accessory number starting with "AC" or "ac", followed by 1 to 8 digits
        // 3. An article number consisting of 1 to 5 digits, followed by a dot, followed by 0 to 3 digits
        // 4. An article number consisting of 1 to 10 digits
        // Each part is separated by the "or" operator (|), ensuring that the article number matches exactly one of these patterns.
        const regexArtno = RegExp('^[A-Za-z][0-9]{1,9}$|^[Aa][Cc][0-9]{1,8}$|^[0-9]{1,5}\\.?[0-9]{0,3}$|^[0-9]{1,10}$');

        if (!suchwert.match(regexArtno)) {
          // full-text search
          artnoResults.empty();
          artnoResults.css({ "display" : "none"});

          // If we have a failed search asset load, show try again error to user
          if (searchAssetLoadingFailed === true) {
            spotlightResults.append(`<p>${globals.trans['lightscout-search'].es_ist_ein_fehler_aufgetreten}</p>`)
            return;
          }


          const fullSearch = document.createElement('fulltext-search-results');
          fullSearch.setAttribute('search-query', suchwert);
          fullSearch.setAttribute('language', navsprache)
          spotlightResults.append(fullSearch);
          return;
        }

        // article serach
        url = confEnv['pdb_api_url']+"/index.php/productdata/list_articles/"+navsprache+"/modus_spotlight_search/true/num/"+encodeURI(suchwert) + "?api=v1.1";

        if ($('[data-target="gui_suchergebnis"]').css('display') == 'none'){ show_artikelnummersuche(); }

        if(!$("#ajax_loader_artnr").length){
          artnoResults.prepend("<div class=\"p-t-3\"> <img src=\"/struktur/img/"+ajax_loader_datei+"\" id=\"ajax_loader_artnr\" alt=\"please wait while loading\" border=\"1px\"/> </div>");
        }

        $.ajax({
          url: url,
          encoding: 'UTF-8',
          type: 'POST',
          //cache: false,
          dataType: "html",
            success: function(transport){
              var antwort = transport;

              // Wir müssen ;articles=... entfernen, da es sonst unnötiges "gehoppel" auf der Seite gibt
              var url = location.href;
              if( (matches = url.match(/\?articles=(.*)/i)) ) {
                history.replaceState('', '', url.replace(matches[0], ''));
              }

              /* Das Perl Skript gibt diese JS zurück:
              <script>suchterm_untouched = 'asd';pkm_range = '';bereiche = '';artikelnummertreffer = '';</script>

              managescripts evalt das JS und strippt es aus der Anwort ss 20150206 */
              antwort = tools.managescripts(antwort);

              /* ID-suchterm_untouched wird vom Perl geschickt - zur Vermeidung sich überholender Suchen */
              var untouched_no_trailing_leading_blanks = decodeURI(response.suchterm_untouched).replace(/^\s+|\s+$/g,'');

              if($('[data-nav-search]:visible').val().replace(/^\s+|\s+$/g,'') == untouched_no_trailing_leading_blanks || $('[data-nav-search]:visible').val().replace(/[&\/\\#,+()$@~%'":*!?<>{}]/gi, "")  == untouched_no_trailing_leading_blanks){
                if(!response.pkm_range && !response.artikelnummertreffer){

                  // nun überflüssiger Informationsblock wird zurückgehalten
                  artnoResults.css({ "display" : "none"});

                  let suchwert = $('[data-nav-search]:visible').val();

                  // Remove leading and trailing blanks
                  suchwert = suchwert.replace(/^\s+|\s+$/g,'');

                  // Remove special character
                  suchwert = suchwert.replace(/[&\/\\#,+()$@~%'":*!?<>{}]/gi, "");

                  const regexArtno = RegExp('^[A-Za-z][0-9]{9}$|^[0-9]{5}\.?[0-9]{3}$');

                  if (suchwert.match(regexArtno)) {
                      // nur eine syntaktisch-korrekte Artikelnummer (xxxxx.xxx) wird hier weiterverarbeitet
                      produktarchivinfo(suchwert);
                  } else {
                    artnoResults.empty().append(antwort);

                    // liste wird in der Aktikelsuche breiter dargestellt als in der Artikelliste - KT
                    artnoResults.find('#article-list-wrap > div').addClass('fix-mobile-width');
                    artnoResults.css({ "display" : "block"});
                  }


                } else {
                  artnoResults.empty().append(antwort);

                  // liste wird in der Aktikelsuche breiter dargestellt als in der Artikelliste - KT
                  artnoResults.find('#article-list-wrap > div').addClass('fix-mobile-width');
                  artnoResults.css({ "display" : "block"});
                }
              }
              /* Wenn wir keine Familie aus dem CMS gefunden haben, dann suchen wir über die spotlightDB (crawlerergebnisse) */
              else if(response.pkm_range.length == 0){

                // wir versuchen falsche doppelsuchen zu vermeiden
                jetzt = new Date();
                var diff_last_search = Math.round(jetzt.getTime()/1000) - time_last_stichwortsuche;
                if(!check_suchwort_aktiv && diff_last_search > 1){

                  jetzt = new Date();
                  time_last_stichwortsuche = jetzt.getTime()/1000;

                  check_suchwort_aktiv = true;
                  new PeriodicalExecuter(function(check_suchwort) {

                    var temp_suchwort = $('[data-nav-search]:visible').val().replace(/[&\/\\#,+()$@~%'":*!?<>{}]/gi, "");
                    suche_stichwort = temp_suchwort;
                    if(temp_suchwort == suche_stichwort && check_suchwort_aktiv){
                      re = new RegExp('^[A-Za-z][0-9]{9}|[0-9]{5}\.?[0-9]{3}');

                      if (temp_suchwort.match(re)) {
                          // nur eine syntaktisch-korrekte Artikelnummer (xxxxx.xxx) wird hier weiterverarbeitet
                          produktarchivinfo(temp_suchwort);
                      } else {
                        artnoResults.empty().append(antwort);

                        // liste wird in der Aktikelsuche breiter dargestellt als in der Artikelliste - KT
                        artnoResults.find('#article-list-wrap > div').addClass('fix-mobile-width');
                        artnoResults.css({ "display" : "block"});
                      }

                      check_suchwort_aktiv = false;
                      check_suchwort.stop();
                    }

                  }, 3);
                }
              }
            },
            complete: function(){
              if(config_data.collectable == true){
                var obj = {'selector' : "[data-target=gui_suchergebnis]"};
                Collector.injectUI(obj); //myerco.js
              }
              after_ajax.start();
            }

        });

        suche_checkproduktnr = $('[data-nav-search]:visible').val();
      } else {
        // Alte Suchwerte werden nicht mehr angezeigt
        artnoResults.css({ "display" : "none"});
        spotlightResults.empty();
      }
    } else if (obj.value.length == 0){
      // Alte Suchwerte werden nicht mehr angezeig
      artnoResults.css({ "display" : "none"});
      spotlightResults.empty();
      suche_checkproduktnr = "";
    }

    return false;
  }

  /* ID-------------------
  Aktionsauswertung */


  if(aktion == "check_artno"){ check_artno(wert); }
  else if(aktion == "clear"){ clear(wert); }
  else if(aktion == "blur"){ blur(wert); }
  else if(aktion == "suchen"){ suchen(wert); }
  else if(aktion == "produktarchiv"){ produktarchiv(wert); }
  else if(aktion == "show_artikelnummersuche"){ show_artikelnummersuche(); }
}

function checksum(klarText){
  var checksum=0;
  for(var i=0; i<klarText.length; i++){
    var encodedChar=klarText.charCodeAt(i);
    checksum=encodedChar+checksum;
  }
  return checksum;
}

var tools = new Object();
tools = {
  /* Wertet JS aus Html-Ajax-Responses aus */
  managescripts:function(data){

    // wir können kein $(data).find('script') machen, da jQuery die scripts wegstriped
    scripts = data.match(/<script(.*?)>(.*?)<\/script>/gi);
    if(scripts) {
      for(var i = 0; i < scripts.length; i++) {
        var script = scripts[i];
        script = script.replace(/<(.*?)script(.*?)>/img, '');
        $.globalEval(script);
      }
    }
    return $(data);
  }
}

/*
 * KT -  04.06.2018
 * Bei der Suche wird nun nur alle 0,3 Sekunden eine Anfrage gestellt.
 */
var waitingForSearch;
$( document ).ready(function(){
  waitingForSearch = window.toolkit.debounce(function(obj){
    finden('suchen', obj);
  }, 300);
});

// Only load the search frontend script when the search is used
const searchInput = document.querySelector('[data-nav-search]');
let searchAssetLoadingFailed = undefined;

if (searchInput) {
  const searchAssetsToLoad = [
    {
      type: 'script',
      url: fullTextSearchPath,
    },
  ]

  const registerFailedSearchAssetLoad = () => {
    searchAssetLoadingFailed = true;
  }

  const registerSuccessSearchAssetLoad = (index) => {
    searchAssetsToLoad.splice(index, 1);
    if (searchAssetsToLoad.length === 0) {
      searchAssetLoadingFailed = false;
    }
  }

  // Load required translations and assets when the search input is focused
  searchInput.addEventListener('focus', () => {
    if (!globals.trans['lightscout-search']) {
      jsTranslation.getContext('lightscout-search');
    }

    if (searchAssetsToLoad.length === 0) return;

    searchAssetsToLoad.forEach((asset, index) => {
      switch (asset.type) {
        case 'script':
          const jsScript = document.createElement('script');
          jsScript.src = asset.url;
          jsScript.onerror = registerFailedSearchAssetLoad;
          jsScript.onload = () => registerSuccessSearchAssetLoad(index);
          document.body.appendChild(jsScript);
          break;
        case 'stylesheet':
          const cssLink = document.createElement('link');
          cssLink.rel = 'stylesheet';
          cssLink.href = asset.url;
          cssLink.onerror = registerFailedSearchAssetLoad;
          cssLink.onload = () => registerSuccessSearchAssetLoad(index);
          document.body.appendChild(cssLink);
          break;
        default:
          return;
      }
    });
  });
}
