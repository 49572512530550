/**
 * Cookie Message
 * Show/Hide the cookie message and handle its localStorage object
 *
 * @dependencies
 * * toolkit.getLocalStorageObject – (toolkit.js)
 * * toolkit.setLocalStorageObject – (toolkit.js)
 *
 * @dependents
 * * $(document).ready(function () { ... }); – (erco.js)
 * * Thirdparty.getInitPlaceholderElement – (third-party.js)
 * * Thirdparty.isEnabled – (third-party.js)
 *
 * @commit
 * * h.bode (2020-03-26) – migrated cookie message logic and integrated localStorage
 * * j.pereira (2021-09-02) – feat(third-party): support activation of all cookies by activation button
 * * j.pereira (2021-09-15) – fix(third-party): prevent init fallback flashing when cookies are accepted
 */

CookieMessage = {
  // CSS class to show/hide the cookie message
  class: 'js-cookie-message',
  registeredObserverCallbacks: [],

  // const values
  ACTION_ALLOW: 'allow',

  cookiesAreEnabled: function () {
    const cookieValue = this.getCrossDomainCookie('cookieMessage');
    if (cookieValue && cookieValue.accepted) {
      return true;
    }

    return !!window.toolkit.getLocalStorageObject('cookieMessage');
  },

  cookiesAreDisagreed: function () {
    const cookieValue = this.getCrossDomainCookie('cookieMessageDisagree');
    return cookieValue && cookieValue.disagreed;
  },

  // Check if the cookie message should be displayed
  check: function () {
    // Check cookies and localStorage
    const cookiesEnabled = this.cookiesAreEnabled();
    const cookieMessageDisagree = this.cookiesAreDisagreed() || sessionStorage.getItem('cookieMessageDisagree');

    if (cookiesEnabled) {
      // Notifying all observers on cookie allowance
      this.triggerObserverCallbacks(this.ACTION_ALLOW);
    }

    // Return if there is a cookieMessage object in localStorage or cookies or the user disagreed
    if (cookiesEnabled || cookieMessageDisagree) {
      return;
    } else {
      // Show the cookie message
      CookieMessage.show();
    }
  },

  // Show the cookie message
  show: function () {
    // open cookiemessage
    document.dispatchEvent(new Event('showCookieMessage'));

    // Submit buttons of the cookie message
    const buttonAgree = document.querySelector('[data-cookie-btn="nav_cookie_agree"]');
    const buttonDisagree = document.querySelector('[data-cookie-btn="nav_cookie_disagree"]');

    // Return if there are no buttons
    if (!buttonAgree || !buttonDisagree) return;

    // Click handler for the submit button (agreed)
    buttonAgree.onclick = function () {
      // Hide the cookie message on submit
      CookieMessage.hide(true);
    };

    // Click handler for the submit button (disagreed)
    buttonDisagree.onclick = function () {
      // Hide the cookie message on submit
      CookieMessage.hide(false);
    };
  },

  // Hide the cookie message
  hide: function (agreed = true) {
    if (agreed) {
      // allow cookies
      this.allowCookies();
    } else {
      sessionStorage.setItem('cookieMessageDisagree', true);
      
      const cookieMessageDisagreeObject = {
        disagreed: true,
      };
      this.setCrossDomainCookie('cookieMessageDisagree', cookieMessageDisagreeObject);
    }

    // hide cookieMessage
    document.dispatchEvent(new Event('hideCookieMessage'));
  },

  registerObserverCallback: function (cb) {
    this.registeredObserverCallbacks.push(cb);
  },

  triggerObserverCallbacks: function (action) {
    // signaling an action triggered in the cookie message
    this.registeredObserverCallbacks.forEach(function (cb) {
      cb(action);
    });
  },

  allowCookies: function () {
    // Create the cookieMessage object
    let cookieMessageStorageObject = {
      accepted: true,
    };

    // Set cookieMessage object in localStorage
    window.toolkit.setLocalStorageObject('cookieMessage', cookieMessageStorageObject, 365 * 24 * 60); // Expires in 1 year

    // Set as cross-domain cookie
    this.setCrossDomainCookie('cookieMessage', cookieMessageStorageObject, 365 * 24 * 60); // Expires in 1 year

    // And remove an existing cookieMessageDisagree sessionStorage item
    sessionStorage.removeItem('cookieMessageDisagree');

    // And remove any existing cookieMessageDisagree cookie
    this.removeCrossDomainCookie('cookieMessageDisagree');

    // Notifying all observers on cookie allowance
    this.triggerObserverCallbacks(this.ACTION_ALLOW);
  },

  // Set the cross-domain cookie value
  setCrossDomainCookie: function (key, value, expiration) {
    const hostname = window.location.hostname;
    const domainMatch = hostname.match(/[^.]+\.[^.]+$/);
    const domain = domainMatch ? domainMatch[0] : hostname;

    const storageObject = {
      value: value,
    };

    if (expiration) {
      storageObject.expiration = new Date().getTime() + expiration * 60 * 1000;
    }

    let cookieString = key + '=' + encodeURIComponent(JSON.stringify(storageObject));

    if (expiration) {
      const date = new Date();
      date.setTime(date.getTime() + expiration * 60 * 1000);
      cookieString += '; expires=' + date.toUTCString();
    }
    
    cookieString += '; domain=.' + domain + '; path=/; SameSite=Lax';
    
    document.cookie = cookieString;
  },

  // Get the cross-domain cookie value
  getCrossDomainCookie: function (key) {
    const nameEQ = key + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        try {
          const cookieObj = JSON.parse(decodeURIComponent(c.substring(nameEQ.length, c.length)));

          // Check if cookie has expired
          if (cookieObj.expiration && new Date().getTime() > cookieObj.expiration) {
            // Cookie has expired, remove it
            this.removeCrossDomainCookie(key);
            return null;
          }

          return cookieObj.value;
        } catch (e) {
          return null;
        }
      }
    }
    return null;
  },

  // Remove the cross-domain cookie
  removeCrossDomainCookie: function (key) {
    const domain = window.location.hostname.split('.').slice(-2).join('.');
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.' + domain + '; path=/;';
  },
};
